.shop-article__heading {
  background: $text-superlight;
  padding: $padding-large 0;
  @include respond-to(extra-small) {
    padding: 20px 0;
  }
  .wrap {
    max-width: $max-width;
    margin: 0 auto;
    display: flex;
    @include respond-to(medium) {
      flex-direction: column;
      align-items: center;
    }

  }
  .text__title {
    text-align: left;
  }
  .text-block {
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: center;
    @include respond-to(medium) {
     width: 100%;
      padding: $padding-mobile;
    }
  }

  .picture {
    width: 50%;
    height: 485px;

    &.basic {
      background: url('#{$images-sets-large}set-wired.png') no-repeat;
      @include respond-to(medium) {
        background-size: contain;
        background-position: center;
      }
    }
    &.wireless {
      background: url('#{$images-sets-large}set-wireless.png') no-repeat;
      @include respond-to(medium) {
        background-size: contain;
        background-position: center;
      }
    }
    &.hot_water_floor {
      background: url('#{$images-sets-large}set-hot-water.png') no-repeat;
      @include respond-to(medium) {
        background-size: contain;
        background-position: center;
      }
    }
    &.electric_floor {
      background: url('#{$images-sets-large}set-electric-floor.png') no-repeat;
      @include respond-to(medium) {
        background-size: contain;
        background-position: center;
      }
    }
    &.base_station {
      background: url('#{$images-devices-large}base-station.png') no-repeat;
      @include respond-to(medium) {
        background-size: contain;
        background-position: center;
      }
    }
    &.motion_sensor {
      background: url('#{$images-devices-large}motion.png') no-repeat;
      @include respond-to(medium) {
        background-size: contain;
        background-position: center;
      }
    }
    &.temperature_sensor {
      background: url('#{$images-devices-large}temperature.png') no-repeat;
      @include respond-to(medium) {
        background-size: contain;
        background-position: center;
      }
    }
    &.wired_regulator {
      background: url('#{$images-devices-large}regulator.png') no-repeat;
      @include respond-to(medium) {
        background-size: contain;
        background-position: center;
      }
    }
    &.wireless_regulator {
      background: url('#{$images-devices-large}wireless-regulator.png') no-repeat;
      @include respond-to(medium) {
        background-size: contain;
        background-position: center;
      }
    }
    &.multi_switch {
      background: url('#{$images-devices-large}multiswitch.png') no-repeat;
      @include respond-to(medium) {
        background-size: contain;
        background-position: center;
      }
    }
    &.probe_sensor {
      background: url('#{$images-devices-large}probe.png') no-repeat;
      @include respond-to(medium) {
        background-size: contain;
        background-position: center;
      }
    }
    &.power_switch {
      background: url('#{$images-devices-large}power.png') no-repeat;
      @include respond-to(medium) {
        background-size: contain;
        background-position: center;
      }
    }
    &.boiler_switch {
      background: url('#{$images-devices-large}boiler.png') no-repeat;
      @include respond-to(medium) {
        background-size: contain;
        background-position: center;
      }
    }
    &.air_quality_sensor {
      background: url('#{$images-devices-large}air.png') no-repeat;
      @include respond-to(medium) {
        background-size: contain;
        background-position: center;
      }
    }
    &.smart_plug {
      background: url('#{$images-devices-large}smart-plug.png') no-repeat;
      @include respond-to(medium) {
        background-size: contain;
        background-position: center;
      }
    }
    @include respond-to(medium) {
      width: 100%;

    }
    @include respond-to(extra-small) {
      height: 360px;
    }
  }

  .price-block {
    margin-top: $padding-extra-small;
    margin-bottom: $padding-extra-small;
    font-size: $font-headline;
  }

  .count {
    display: flex;
    color: $text-grey-light;
    border: 1px solid $lightest-grey;
    line-height: 40px;
    width: 120px;
    margin-bottom: 8px;
    .quantity {
      text-align: center;
      height: 40px;
      width: 40px;
      font-size: 22px;
    }
    .minus, .plus {
      cursor: pointer;
      text-align: center;
      height: 40px;
      line-height: 40px;
      font-size: 22px;
      width: 40px;
      color: $text-grey-light;
      user-select: none;
    }
    .minus {
      border-left: 1px solid $lightest-grey;
      border-right: 1px solid $lightest-grey;
    }
  }
  .add-to-cart {
    a {
      margin: 0;
    }
  }
}

.shop-article__description {
  color:white;
  background-color: $darkest-blue;
  .active{
    background-color: white;
    color: $darkest-blue;
  }
  .show-specification{
    .white:hover{
      color: $darkest-blue;
    }
  }
  &.base_station, &.motion_sensor, &.power_switch{
    background-color: $blue;
    .active{
      color:$blue;
    }
    .show-specification{
      .white:hover{
        color: $blue;
      }
    }
  }
  &.temperature_sensor, &.air_quality_sensor, &.probe_sensor{
    background-color: $primary;
    .active{
      color:$primary;
    }
    .show-specification{
      .white:hover{
        color: $primary;
      }
    }
  }
  &.multi_switch, &.wireless_regulator{
    background-color: #e8a500;
    .active{
      color: #e8a500;
    }
    .show-specification{
      .white:hover{
        color: #e8a500;
      }
    }
  }
  .wrap{
    max-width: $max-width;
    margin: 0 auto;
    padding: $padding-large 0 0 0;
    @include respond-to(extra-small){
      padding-top: $padding-medium;
    }
  }
  .set__title{
    padding: 0 $padding-tiny;
  }
  .device__slider{
    height: 370px;
    @include respond-to(medium-large){
      height: 400px;
    }
    @include respond-to(medium){
      height: 500px;
    }
    @include respond-to(small) {
      height: 710px;
    }
    @include respond-to(six-hundred) {
      height: 784px;
    }
    @include respond-to(extra-small){
      height: 696px;
    }

  }
  .specification__slider{
    height: 460px;

    @include respond-to(medium-large){
      height: 570px;
    }
    @include respond-to(small) {
      height: 570px;
    }
    @include respond-to(six-hundred) {
      height: 950px;
    }
    @include respond-to(extra-small){
      height: 980px;
    }

    &.ng-hide-add, &.ng-hide-remove {
      transition: 1s;
      transform: translateZ(0);
    }
    &.ng-hide-add, &.ng-hide-remove.ng-hide-remove-active {
      height:460px;
      @include respond-to(medium-large){
        height: 570px;
      }
      @include respond-to(small) {
        height: 570px;
      }
      @include respond-to(six-hundred) {
        height: 950px;
      }
      @include respond-to(extra-small){
        height: 980px;
      }
    }
    &.ng-hide-add.ng-hide-add-active { height: 0; }
    &.ng-hide-remove { height: 0; }
  }
  .bottom--padding {
    height: $padding-large;
    @include respond-to(extra-small){
      height: $padding-medium;
    }

    &.ng-hide-add, &.ng-hide-remove {
      transition: 1s;
      transform: translateZ(0);
    }
    &.ng-hide-add, &.ng-hide-remove.ng-hide-remove-active {
      height: $padding-large;
    }
    &.ng-hide-add.ng-hide-add-active { height: 0; }
    &.ng-hide-remove { height: 0; }
  }
}
.shop-article--animation{
  .ng-hide-add, .ng-hide-remove {
    transition: all linear 0.3s;
    transform: translateZ(0);
  }
  .ng-hide-add { transform: translateX(0) }
  .ng-hide-add.ng-hide-add-active { transform: translateX(-100%); }
  .ng-hide-remove { transform: translateX(100%) }
  .ng-hide-remove.ng-hide-remove-active { transform: translateX(0);}
}
.set__devices {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  padding: $padding-normal;
  .set__device {
    margin: 7px;
    padding: 7px;
    text-transform: uppercase;
    font-size: $font-body;
    border-radius: $border-radius;
    cursor: pointer;
    text-align: center;
  }
  .active{
    background-color: white;
  }
}
.device__slider{
  width: 100%;
  overflow: hidden;
  position: relative;
}
.description__container{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
.description__content{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .slider {
    flex-wrap: wrap;
    flex: 1 1 40%;
    padding: 0 $padding-normal;
    @include respond-to(extra-small){
      padding: 0
    }
    .specification__media {
      height: 340px;
      width: 100%;
      min-width: 300px;
      flex-wrap: wrap;
      @include respond-to(medium){
        width: 100%;
      }
      @include respond-to(extra-small){
        height: 280px;
      }
      .slide{
        background-size: contain;
        height: 95%;
        padding: 0 20px;
        @include respond-to(extra-small){
          max-height: 230px;
        }
        .measure-1, .measure-2, .measure-3 {
          height: 100%;
          @include respond-to(extra-small){
            background-size: contain;
          }
        }
      }
      .container {
        background: none;
        height: auto;
        max-width: 530px;
        min-width: 336px;
        @include respond-to(extra-small){
          min-width: 0;
        }
      }
      .specification__info{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .specification__description {
          padding: $padding-small $padding-medium;
          @include respond-to(extra-small){
            padding: $padding-mobile;
          }
        }

      }



      .arrow {
        opacity: 0.5;
        i{
          color: rgba(255,255,255,0.8);
        }
      }

      .arrow.prev:hover, .arrow.next:hover {
        opacity: 1;
      }

      .dots .dot a {
        background: rgba(255,255,255,0.8);
      }

      .dots .dot a.active {
        border-color: rgba(255,255,255,0.8);
        background-color: transparent;
      }
    }
  }
  .description__text{
    padding: 0 $padding-normal;
    flex: 1 1 40%;
    color: white;
    display: flex;
    flex-wrap: wrap;
    @include respond-to(small){
      flex-basis: 100%;
    }
    @include respond-to(extra-small){
      padding: 0 $padding-mobile
    }
    .text__title{
      text-align: left;
      @include respond-to(extra-small){
        text-align: center;
      }
    }
    .text__content{
      padding: $padding-normal 0;
    }
    .show-specification{
      align-self: flex-end;
    }
  }
}
.specification__slider{
  width: 100%;
  overflow: hidden;
  position: relative;
}
.specification__content{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  .specification__animation{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    color: white;
    padding-top: $padding-normal;
    @include respond-to(extra-small) {
      justify-content: flex-start;
      text-align: center;
    }
    .seperator{
      flex-basis: 100%;
      display: flex;
      justify-content: center;
      height: 1px;
      .seperator--line{
        flex-basis: 90%;
        background: rgba(255,255,255,0.17);
      }
    }
    .list {
      width: 342px;
      padding: $padding-tiny 0 $padding-tiny $padding-small;
      @include respond-to(small){
        width: 290px;
      }
      @include respond-to(extra-small){
        width: 100%;
        padding: $padding-mobile $padding-small;
      }
      .header__row{
        padding-bottom: $padding-tiny;
        font-size: $font-headline;
        line-height: $line-height-headline;
        i {padding-right: 8px}
      }
      .cell{
        font-size: $font-small-body;
        line-height: $line-height-small-body;
      }
    }
  }
}



.measure-block {
  display: flex;
  min-height: 270px;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 50%;
  @include respond-to(extra-small) {
    min-height: 278px;
  }
  &>div {
    flex: 1 1 0;
    min-width: 320px;
  }
  .measure-base_station {
    &.measure-1 {
      background: url('#{$images-specs}base-front@2x.png') no-repeat center;
    }
    &.measure-2 {
      background: url('#{$images-specs}base-left@2x.png') no-repeat center;
    }
    &.measure-3 {
      background: url('#{$images-specs}base-top@2x.png') no-repeat center;
    }
  }
  .measure-temperature_sensor {
    &.measure-1 {
      background: url('#{$images-specs}temperature-front@2x.png') no-repeat center;
    }
    &.measure-2 {
      background: url('#{$images-specs}temperature-side@2x.png') no-repeat center;
    }
    &.measure-3 {
      background: url('#{$images-specs}temperature-top@2x.png') no-repeat center;
    }
  }
  .measure-motion_sensor {
    &.measure-1 {
      background: url('#{$images-specs}motion-front@2x.png') no-repeat center;
    }
    &.measure-2 {
      background: url('#{$images-specs}motion-left@2x.png') no-repeat center;
    }
    &.measure-3 {
      background: url('#{$images-specs}motion-top@2x.png') no-repeat center;
    }
  }
  .measure-multi_switch {
    &.measure-1 {
      background: url('#{$images-specs}multiswitch-front@2x.png') no-repeat center;
      background-size: contain;
    }
    &.measure-2 {
      background: url('#{$images-specs}multiswitch-persp@2x.png') no-repeat center;
      background-size: contain;
    }
    &.measure-3 {
      flex: 0;
    }
  }
  .measure-wired_regulator {
    &.measure-1 {
      background: url('#{$images-specs}wired-top@2x.png') no-repeat center;
    }
    &.measure-2 {
      background: url('#{$images-specs}wired-side@2x.png') no-repeat center;
    }
    &.measure-3 {
      background: url('#{$images-specs}wired-front@2x.png') no-repeat center;
    }
  }
  .measure-wireless_regulator {
    &.measure-1 {
      background: url('#{$images-specs}wireless-front@2x.png') no-repeat center;
    }
    &.measure-2 {
      background: url('#{$images-specs}wireless-left@2x.png') no-repeat center;
    }
    &.measure-3 {
      background: url('#{$images-specs}wireless-top@2x.png') no-repeat center;
    }
  }
  .measure-probe_sensor {
    &.measure-1 {
      background: url('#{$images-specs}probe-front@2x.png') no-repeat center;
    }
    &.measure-2 {
      background: url('#{$images-specs}probe-left@2x.png') no-repeat center;
    }
    &.measure-3 {
      background: url('#{$images-specs}probe-top@2x.png') no-repeat center;
    }
  }
  .measure-power_switch {
    &.measure-1 {
      background: url('#{$images-specs}power-front@2x.png') no-repeat center;
    }
    &.measure-2 {
      background: url('#{$images-specs}power-left@2x.png') no-repeat center;
    }
    &.measure-3 {
      background: url('#{$images-specs}power-top@2x.png') no-repeat center;
    }
  }
  .measure-boiler_switch {
    &.measure-1 {
      background: url('#{$images-specs}boiler-front@2x.png') no-repeat center;
    }
    &.measure-2 {
      background: url('#{$images-specs}boiler-left@2x.png') no-repeat center;
    }
    &.measure-3 {
      background: url('#{$images-specs}boiler-top@2x.png') no-repeat center;
    }
  }
  .measure-air_quality_sensor {
    &.measure-1 {
      background: url('#{$images-specs}air-front@2x.png') no-repeat center;
    }
    &.measure-2 {
      background: url('#{$images-specs}air-side@2x.png') no-repeat center;
    }
    &.measure-3 {
      background: url('#{$images-specs}air-top@2x.png') no-repeat center;
    }
  }
  .measure-smart_plug {
    &.measure-1 {
      background: url('#{$images-specs}wall_plug-front@2x.png') no-repeat center;
    }
    &.measure-2 {
      background: url('#{$images-specs}wall_plug-side@2x.png') no-repeat center;
    }
    //&.measure-3 {
    //  background: url('#{$images-specs}wall_plug-top@2x.png') no-repeat center;
    //}
  }
}

.shop-article__compatible {
  background: white;
  .wrap {
    margin: 0 auto;
    max-width: $max-width;
    padding: $padding-large 0;

  }
  .text__title {
    margin-bottom: 14px;
  }
  .text__content {
    text-align: center;
  }
  .compatibility-block {
    display: flex;
    margin-top: $padding-extra-small;
    padding: $padding-tiny;
    flex-wrap: wrap;
    justify-content: center;
  }
  .comp {
    flex: 1 1 0;
    min-width: 200px;
    margin: 14px;
  }

  .picture {
    height: 177px;
    width: 177px;
    margin: 0 auto;
    &.gas_boiler {
      background: url('#{$images-heating}gas.png') no-repeat center;
    }
    &.central_heating {
      background: url('#{$images-heating}central.png') no-repeat center;
    }
    &.heat_pump {
      background: url('#{$images-heating}heat.png') no-repeat center;
    }
    &.electricity {
      background: url('#{$images-heating}electricity.png') no-repeat center;
    }
    &.gas_electricity {
      background: url('#{$images-heating}gas-electicity.png') no-repeat center;
    }

  }

  .body {
    text-align: center;
    padding: $padding-tiny;
  }
  .text {
    font-size: 16px;
    line-height: 1.5;
  }
  .title {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.5;
  }
}

.shop-article__customize {
  background: $yellow;
  color: white;
  .wrap {
    padding: $padding-large $padding-tiny;
    max-width: $max-width;
    margin: 0 auto;
    display: flex;
    @include respond-to(small) {
      flex-direction: column;
      align-items: center;
    }
    & > div {
      flex: 1 1 0;
    }

  }
  .text-block {
    text-align: right;
    @include respond-to(small) {
      text-align: center;
      margin-bottom: $padding-extra-small;
    }
    .text__title {
      text-align: right;
      margin-bottom: 14px;
      @include respond-to(small) {
        text-align: center;
      }
    }
    .text__content {
      margin-bottom: 40px;
    }
  }
  .custom-set {
    background: white;
    color: $yellow;
    margin: 0;
    float: right;
    @include respond-to(small) {
      float: none;
      margin: 0 auto;
    }
  }
  .row {
    display: flex;
    justify-content: center;
  }
  .picture-block {
    .picture {
      width: 122px;
      height: 122px;
      @include respond-to(extra-small) {
        width: 100px;
        height: 100px;
        background-size: cover;
      }
      &.base_station {
        background: url('#{$images-devices-small}base-station.png') no-repeat center;
      }
      &.temperature_sensor {
        background: url('#{$images-devices-small}temperature.png') no-repeat center;
      }
      &.motion_sensor {
        background: url('#{$images-devices-small}motion.png') no-repeat center;
      }
      &.multi_switch {
        background: url('#{$images-devices-small}multiswitch.png') no-repeat center;
      }
      &.wired_regulator {
        background: url('#{$images-devices-small}regulator.png') no-repeat center;
      }
      &.wireless_regulator {
        background: url('#{$images-devices-small}wireless-regulator.png') no-repeat center;
      }
    }
  }
}

.device-page {
  .article--setup {
    background: $text-superlight;
  }
  &.set {
    word-break: normal;
    .article--setup {
      background: white;
    }
    .shop-article__compatible {
      background: $text-superlight;
    }
  }
}

