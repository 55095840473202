.solutions__list {
  width: 100%;
  background: #F2F3F5;
  .item__media {
    padding-top: 0;
    height: 200px;
  }
}
.solution__title {
  padding: $padding-normal 0;
}

.solutions__wrap {
  max-width: $max-width;
  padding-bottom: 40px;
  margin: 0 auto;

  .solutions__heading{
    padding: $padding-extra-small $padding-extra-small 0  $padding-extra-small;
    .text__title{
      padding-bottom: 12px;
    }
  }

  .solutions__help {
    padding: $padding-small;
    @include respond-to(extra-small){
      padding-bottom: 0;
    }
    .solutions__help--text{
      font-size: $font-headline;
      line-height: $line-height-headline;
      color:$text-grey;
      text-align: center;
      font-weight: bold;
    }
    .solutions__help--contact{
      font-size: $font-subhead;
      line-height: $line-height-headline;
      color:$text-grey;
      text-align: center;
    }
  }

  .solutions__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .solution__item {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    text-transform: none;
    background: #FFFFFF;
    border-radius: $border-radius;
    box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.3);
    cursor: pointer;
    margin: $padding-extra-small;
    max-width: 350px;
    min-width: 350px;
    height: 330px;
    @include respond-to(small){
      max-width: 340px;
      min-width: 340px;
    }
    @include respond-to(extra-small){
      min-width: 280px;
      max-width: none;
      height: auto;
      margin: $padding-extra-small 8px;
    }
  }

  .solution__media {
    .image {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      height: 200px;
      max-width: 100%;
      margin: 12px;
      @include respond-to(extra-small){
        height: 160px;
      }
    }
    .solution-1{
      background: url('#{$images-solutions}small/simple_istaba.png') no-repeat top;
      background-size: contain;
    }
    .solution-2{
      background: url('#{$images-solutions}small/2istabas.png') no-repeat top;
      background-size: contain;
    }
    .solution-3{
      background: url('#{$images-solutions}small/grida2.png') no-repeat top;
      background-size: contain;
    }
    .solution-4{
      background: url('#{$images-solutions}small/grida.png') no-repeat top;
      background-size: contain;
    }
    .solution-5{
      background: url('#{$images-solutions}small/stavvadi.png') no-repeat top;
      background-size: contain;
    }
    .solution-6{
      background: url('#{$images-solutions}small/stavvadi2.png') no-repeat top;
      background-size: contain;
    }
    .solution-7{
      background: url('#{$images-solutions}small/ele.png') no-repeat top;
      background-size: contain;
    }
  }

  .solution__title {
    font-weight: bold;
    text-align: center;
    padding: 0 12px 12px 12px;
  }

  .solution__subtitle {
    font-weight: normal;
    text-align: center;
    line-height: 26px;
    padding: 0 12px 12px 12px;
  }
}

.item__media {
  height: 200px;
  width: 100%;
  background-color: $lightest-grey;
  .media{
    height: 100%;
  }
  .solution-1{
    background: url('#{$images-solutions}headers/gaz.png') no-repeat top;
  }
  .solution-2{
    background: url('#{$images-solutions}headers/radiator.png') no-repeat top;
  }
  .solution-3{
    background: url('#{$images-solutions}headers/heating.png') no-repeat top;
  }
  .solution-4{
    background: url('#{$images-solutions}headers/radiator.png') no-repeat top;
  }
  .solution-5{
    background: url('#{$images-solutions}headers/home.png') no-repeat top;
  }
  .solution-6{
    background: url('#{$images-solutions}headers/home.png') no-repeat top;
  }
  .solution-7{
    background: url('#{$images-solutions}headers/elec.png') no-repeat top;
  }
  .main__header{
    background: url('#{$images-solutions}headers/main.png') no-repeat bottom;
  }

}

//Solution view content
.solution__wrap {

  display: flex !important;
  flex-direction: column;
  margin: 0 auto;
  max-width: $max-width;
  width: 100%;
  height: 100%;

  .item__content {
    padding: $padding-extra-small $padding-tiny;
    padding-top: 0;

    .item-heading {
      .solution__media {
        .image {
          height: 666px;
          max-width: 100%;
          margin: 12px;
          @include respond-to(medium-large){
            height: 587px;
          }
          @include respond-to(medium){
            height: 475px;
          }
          @include respond-to(small){
            height: 350px;
          }
          @include respond-to(six-hundred){
            height: 238px;
          }
          @include respond-to(extra-small){
            height: 160px;
          }
        }
      }
      .LV {
        .solution-1{
          background: url('#{$images-solutions}large/LV/simple_istaba.png') no-repeat top;
          background-size: contain;
        }
        .solution-2{
          background: url('#{$images-solutions}large/LV/2istabas.png') no-repeat top;
          background-size: contain;
        }
        .solution-3{
          background: url('#{$images-solutions}large/LV/grida2.png') no-repeat top;
          background-size: contain;
        }
        .solution-4{
          background: url('#{$images-solutions}large/LV/grida.png') no-repeat top;
          background-size: contain;
        }
        .solution-5{
          background: url('#{$images-solutions}large/LV/stavvadi.png') no-repeat top;
          background-size: contain;
        }
        .solution-6{
          background: url('#{$images-solutions}large/LV/stavvadi2.png') no-repeat top;
          background-size: contain;
        }
        .solution-7{
          background: url('#{$images-solutions}large/LV/ele.png') no-repeat top;
          background-size: contain;
        }}
      .EN {
        .solution-1{
          background: url('#{$images-solutions}large/EN/simple_istaba.png') no-repeat top;
          background-size: contain;
        }
        .solution-2{
          background: url('#{$images-solutions}large/EN/2istabas.png') no-repeat top;
          background-size: contain;
        }
        .solution-3{
          background: url('#{$images-solutions}large/EN/grida2.png') no-repeat top;
          background-size: contain;
        }
        .solution-4{
          background: url('#{$images-solutions}large/EN/grida.png') no-repeat top;
          background-size: contain;
        }
        .solution-5{
          background: url('#{$images-solutions}large/EN/stavvadi.png') no-repeat top;
          background-size: contain;
        }
        .solution-6{
          background: url('#{$images-solutions}large/EN/stavvadi2.png') no-repeat top;
          background-size: contain;
        }
        .solution-7{
          background: url('#{$images-solutions}large/EN/ele.png') no-repeat top;
          background-size: contain;
        }}
      p {
        text-indent: 2em;
      }
    }

    .item-list {
      padding-top: $padding-small;
      .list__heading, li{
        line-height: $line-height-body;
      }
      ul {
        padding-left: 2em;
      }
      li {
        list-style-type: circle;
        a {
          color: inherit;
          font-size: inherit;
          text-transform: inherit;
        }
      }
    }

    .item-text {
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      @include respond-to(small) {
        flex-direction: column;
      }
      align-items: flex-start;
      width: 100%;
      .item__container{
        width: 100%;
        .text__content{
          p {
            text-indent: 2em;
          }
        }
      }

      & .question {
        text-align: center;
        font-weight: bold;
        font-size: $font-headline;
        line-height: $line-height-headline;
      }
    }

    & .nav__buttons {
      display: flex;
      justify-content: space-between;
      padding: $padding-medium 0;
      & a {
        text-transform: none;
        color: $text-grey-light;
        &:hover {
          color: $primary-dark;
        }
      }
    }
  }
}